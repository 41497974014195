import { Route, Routes } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { CookiesProvider } from "react-cookie";
import { useState } from "react";
import { trpc } from "./utils/trpc";
import MenuContainer from "./components/menu/Menu";
import ErrorPage from "./components/ErrorPage";
import OrderContainer from "./components/order/Order";

const engine = new Styletron();

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 1 } },
      })
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: process.env.REACT_APP_TRPC_API_ENDPOINT || "",
        }),
      ],
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <CookiesProvider defaultSetOptions={{ path: "/" }}>
              <Routes>
                <Route path="/order/:id/:token" element={<OrderContainer />} />
                <Route path="/:slug/*" element={<MenuContainer />} />
                <Route path="/*" element={<ErrorPage error="Not found" />} />
              </Routes>
            </CookiesProvider>
          </BaseProvider>
        </StyletronProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
